"use client";

import {
  FC,
  LegacyRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import useIntersectionObserver, {
  IOptions,
} from "apps/website/hooks/useIntersectionObserver";
import IconButton from "apps/website/components/base/IconButton/IconButton";

import Image from "../Image/Image";

import { PlayPausePosition, playPausePositionMap } from "./Video.map";

export type IVideoPath = string;
export type TVideoLoading = "default" | "onPlay";

export interface IVideo {
  video: IVideoPath;
  poster: string;
  cover?: boolean;
  controls?: boolean;
  canPause?: boolean;
  playPausePosition?: PlayPausePosition;
  loading?: TVideoLoading;
  type?: string;
  muted?: boolean;
}

const VIDEO_INTERSECTION_DEFAULTS: IOptions = {
  root: null,
  rootMargin: "0px",
  threshold: 0.5,
};

const Video: FC<IVideo> = ({ video, poster, cover = false, controls = true, canPause = true, playPausePosition = "default", loading = "default", type = "video/mp4", muted = true }) => {

  const [ videoWrapper, isVisible ] = useIntersectionObserver(VIDEO_INTERSECTION_DEFAULTS);
  const videoElement = useRef<HTMLVideoElement>(null);
  const hiddenPause = useRef<HTMLButtonElement>(null);
  const videoElementSrc = useRef<HTMLSourceElement>(null);
  const [ isPlaying, setIsPlaying ] = useState(cover);
  const [ videoOpacity, setVideoOpacity ] = useState("opacity-0");
  const [ playActivatedByUser, setPlayActivatedByUser ] = useState(false);

  const onClickHiddenPause = useCallback(() => {
    (hiddenPause?.current?.previousElementSibling as HTMLButtonElement).focus();
    setIsPlaying(false);
  }, [ hiddenPause, setIsPlaying ]);

  const loadVideo = useCallback(() => {
    if (videoElementSrc?.current && videoElement?.current) {
      videoElementSrc.current.src = videoElementSrc.current.getAttribute("data-src") ?? "";
      // TODO: Make loading smoother (fade in)
      videoElement.current.load();
    }
  }, [ videoElementSrc, videoElement ]);

  useEffect(() => {
    if (videoElementSrc?.current?.src || loading === "onPlay") return;
    // Don't load video until it's necessary
    if (isVisible && videoElementSrc?.current && videoElement?.current) {
      loadVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isVisible ]);

  useEffect(() => {
    if (isPlaying && !isVisible) {
      setIsPlaying(false);
    }
  }, [ isVisible, isPlaying, setIsPlaying ]);

  useEffect(() => {
    if (!videoElement.current) return;
    if (isPlaying) {
      void videoElement.current.play();
      if (playActivatedByUser) {
        hiddenPause?.current?.focus();
      }
    } else {
      void videoElement.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isPlaying ]);

  useEffect(() => {
    const videoRef = videoElement.current;
    if (!cover && videoRef) {
      videoRef.onended = () => setIsPlaying(false);
    }

    const onPlay = () => {
      if (typeof window !== undefined && window?.dataLayer) {
        try {
          window.dataLayer.push({
            event: "gaevent",
            category: "VIDEO_ELEMENT_EVENT",
            action: "VIDEO_PLAYED",
            label: video,
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("failed to push dataLayer event for VIDEO_ELEMENT_EVENT.VIDEO_PLAYED");
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    };

    const onPause = () => {
      if (typeof window !== undefined && window?.dataLayer) {
        try {
          window.dataLayer.push({
            event: "gaevent",
            category: "VIDEO_ELEMENT_EVENT",
            action: "VIDEO_PAUSED",
            label: video,
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("failed to push dataLayer event for VIDEO_ELEMENT_EVENT.VIDEO_PAUSED");
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    };

    videoRef?.addEventListener("play", onPlay);
    videoRef?.addEventListener("pause", onPause);

    return () => {
      videoRef?.removeEventListener("play", onPlay);
      videoRef?.removeEventListener("pause", onPause);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUserActionPlayPause = () => {
    if (!isPlaying && !videoElementSrc?.current?.src) {
      loadVideo();
    }
    setPlayActivatedByUser(!playActivatedByUser);
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    videoElement?.current?.addEventListener("loadeddata", () => setVideoOpacity("opacity-1"));
  }, []);

  return (
    <div data-component="Video" ref={ videoWrapper as LegacyRef<HTMLDivElement> } className={ `bg-gray-200 w-full group ${cover ? "w-full h-full absolute top-0 left-0" : "relative"}` }>
      { videoOpacity === "opacity-0" && (
        <>
          { /* Width and height 0 is fine */ }
          <Image image={ { src: poster, height: 0, width: 0 } } alt="Video poster" cover />
        </>
      ) }
      <video
        ref={ videoElement }
        className={ `lazy w-full relative z-10 transition-opacity ease-in-out duration-300 ${videoOpacity} ${cover && "h-full absolute top-0 left-0 object-cover"}` }
        autoPlay={ cover }
        muted={ muted }
        loop={ cover }
        poster={ poster }
        playsInline={ cover }
        controls={ !cover && controls }
      >
        <source ref={ videoElementSrc } data-src={ video } type={ type } />
      </video>
      { canPause && (
        <IconButton
          icon={ isPlaying ? "pause" : "play" }
          size="xlarge"
          label={ isPlaying ? "Pause video" : "Play video" }
          onClick={ onUserActionPlayPause }
          className={ `focus:opacity-100 group-hover:opacity-100 transition-opacity duration-300 z-20 absolute left-1/2 -translate-x-1/2 ${isPlaying && "opacity-0"} ${cover && "hidden"} ${playPausePositionMap[playPausePosition]}` }
        />
      ) }
      { /* This button should be visible on focus for accessibility */ }
      <button
        ref={ hiddenPause }
        tabIndex={ isPlaying ? 0 : -1 }
        onClick={ onClickHiddenPause }
        className="-z-50 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 focus:z-50"
      >
        Pause video
      </button>
    </div>
  );
};

export default memo(Video);
